<template>
  <div class="integration-list-page">
    <div class="integrations-container" v-loading="loadingComp">
      <div
        class="integration-row bordered-box"
        v-for="integration in availableIntegrations"
        :key="integration"
      >
        <div class="name">{{ integration }}</div>
        <div>
          <el-button
            :disabled="!enabledIntegrations.includes(integration)"
            @click="configureIntegration(integration)"
            >Configure</el-button
          >
        </div>
        <div>
          <el-switch
            :value="enabledIntegrations.includes(integration)"
            v-loading="loadingIntegrations.includes(integration)"
            @change="newVal => toggleIntegration(integration, newVal)"
          ></el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOrgEnabledIntegrations,
  getAvailableIntegrations,
  enableIntegrations,
  disableIntegrations,
} from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';

export default {
  name: 'IntegrationsList',
  data() {
    return {
      enabledIntegrations: [],
      availableIntegrations: [],
      loadingIntegrations: [],
      loadingComp: true,
    };
  },
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.enabledIntegrations = await getOrgEnabledIntegrations(this.org.id);
    this.availableIntegrations = await getAvailableIntegrations();
    this.loadingComp = false;
  },
  methods: {
    async toggleIntegration(integration, enableIntegration) {
      if (this.loadingIntegrations.length !== 0) return;

      this.loadingIntegrations.push(integration);

      try {
        if (enableIntegration) {
          this.enabledIntegrations = await enableIntegrations({
            orgId: this.org.id,
            integrations: [integration],
          });
        } else {
          this.enabledIntegrations = await disableIntegrations({
            orgId: this.org.id,
            integrations: [integration],
          });
        }
      } catch (error) {
        console.error(error);
        if (enableIntegration) {
          alertError(error, `Failed to enable integration ${integration}.`);
        } else {
          alertError(error, `Failed to disable integration ${integration}.`);
        }
      }

      this.loadingIntegrations = this.loadingIntegrations.filter(
        currIntegration => currIntegration !== integration,
      );
    },
    configureIntegration(integration) {
      this.$emit('configureClicked', integration);
    },
  },
};
</script>

<style lang="scss">
.integration-list-page {
  .integrations-container {
    min-height: 100px;
    max-width: 800px;
    // margin: auto;

    .integration-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        // font-weight: bold;
        flex-basis: 40%;
      }
    }
  }
}
</style>
