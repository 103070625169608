
import { defineComponent, ref, computed } from 'vue';
import { useEditUserForm, useCreateUserForm } from '@/composables/useUserForm';
import { useAuth } from '@/composables/useAuth';
import { useOrgManager } from '@/composables/useOrgManager';
import router from '@/router';
import { FetchStates } from '@/helpers/fetch-states';
import { deleteUsers, rehireUsers } from '@/api/accidentplan-api';
import IntegrationsList from '@/components/orgs/integration_config/IntegrationsList.vue';

export default defineComponent({
  name: 'UserPage',
  components: { IntegrationsList },
  setup() {
    const isEditing = computed(() =>
      router.currentRoute.value.path.includes('/users/edit'),
    );

    const userId = computed(() => +router.currentRoute.value.params['id']);

    const formRef = ref(null);
    const useForm = useEditUserForm(formRef, userId);

    const { isVAR } = useAuth();
    const { orgs } = useOrgManager();

    const routeToUsers = () => router.push({ name: 'UsersPage' });

    const routeBack = () => {
      if (window.history.state.back === null) {
        routeToUsers();
      } else {
        router.back();
      }
    };

    const terminateUser = async () => {
      await deleteUsers([userId.value]);
      await useForm.fetchUser();
    };
    const rehireUser = async () => {
      await rehireUsers([userId.value]);
      await useForm.fetchUser();
    };

    const canEdit = computed(() => !useForm.signinProviderNotNull.value);

    return {
      ...useForm,
      formRef,
      isEditing,
      isVAR,
      orgs,
      routeToUsers,
      routeBack,
      terminateUser,
      rehireUser,
      canEdit,
      FetchStates,
    };
  },
});
